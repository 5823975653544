<template>
    <section>
      <div class="w-full">
        <div class="rounded-lg shadow-md">
            <div class="lg:flex">
              <div class="imagen-bg w-full lg:w-4/12 flex">
                <div class="w-full flex justify-center items-center p-4">
                  <img v-if="dataPersona.imagen" class="w-40 h-40 rounded-full" :src="env + dataPersona.imagen" alt="">
                  <img v-else class="w-40 h-40 rounded-full" src="../../../../../images/user.jpg" alt="">
                </div>
              </div>
              <div class="w-full lg:w-8/12 p-4 lg:p-10 flex items-center">
                <div>
                  <p class="text-2xl font-bold">
                    {{dataPersona.full_name}}
                  </p>
                  <p class="text-lg font-bold">
                    {{cargo.name}}
                  </p>
                  <div class="mt-4 lg:flex gap-10">
                    <div class="flex">
                      <i class="pi pi-envelope mr-2 text-sm"></i>
                      <p class="font-bold">{{ dataPersona.email }}</p>
                    </div>
                    <div class="flex">
                      <i class="pi pi-phone mr-2 text-sm"></i>
                      <p class="font-bold">{{ dataPersona.telefono }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  </template>
  <script>

  import { computed } from 'vue'
  import empleadosStore from '../../../../../stores/empleados.store'

  export default {
    name: 'ComponentHeader',
    setup () {
      // References
      const dataEmpleado = computed(() => empleadosStore.getters._dataEmpleado)
      const dataPersona = computed(() => empleadosStore.getters._dataPersona)
      const cargo = computed(() => empleadosStore.getters._cargo)
      // Data
      const env = process.env.VUE_APP_MICROSERVICE_API_USUARIOS.split('/api')[0]
      // Methods

      return {
        env,
        dataEmpleado,
        dataPersona,
        cargo
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  .imagen-bg {
    background-image: url(../../../../../images/Enmascarar-grupo-8.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
  }
  ::v-deep(.p-dropdown-trigger) {
    display: none;

  }
  ::v-deep(.p-button.p-component.p-button-icon-only.p-datepicker-trigger) {
    background-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
    border-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
}
  </style>
